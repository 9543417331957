import { Component, OnInit } from '@angular/core';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Location } from '@angular/common';
import { CandidateService } from '../services/candidate.service';
import { ApiService } from '../services/api.service';
import { map } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-resume-generate',
  templateUrl: './resume-generate.component.html',
  styleUrls: [ './resume-generate.component.css' ]
})
export class ResumeGenerateComponent implements OnInit {

  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
  setLanguage(lng){
    console.log(lng);
    if(lng === 'en'){
      localStorage.setItem('language','en');
    }
    else{
      localStorage.setItem('language','ger');
    }
    this.translate.use(lng);
  }

  userData = {
    education: [{
      education: '',
      start_year: '',
      end_year: '',
      institution_name: '',
      programme: '',
      description: '',
      userId: '',
      uid: ''
    }],
    workExp: [{
      position: '',
      company_name: '',
      start_year: '',
      end_year: '',
      description: '',
      userId: '',
      uid: ''
    }],
    skills: [{
      skillname: '',
      skillrate: '',
      userId: '',
      uid: ''
    }],
    awards: [{
      name: '',
      year: '',
      description: '',
      userId: '',
      uid: ''
    }],
    profile: {
      fullname: '',
      userId: '',
      job_title: '',
      experience: '',
      age: '',
      current_sal_min: 0,
      current_sal_max: 0,
      expected_sal_min: 0,
      expected_sal_max: 0,
      education_levels: '',
      categories: '',
      description: ''
    }
  };
  // canId = JSON.parse(localStorage.getItem('canSingle'));
  canId = localStorage.getItem('canSingle');
  constructor(private loc: Location, private canSer: CandidateService, private api: ApiService,  public translate: TranslateService) {
    if(localStorage.getItem('language')){
      translate.use(localStorage.getItem('language'));
    }
    else{
      translate.setDefaultLang('Portuguese');//2
      translate.addLangs(['English', 'Portuguese']);
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
    }
   }
  ngOnInit() {
    // console.log(JSON.parse(this.canId));
    this.api.getCandidateEducationById(this.canId)
      .pipe(map(actions => actions.map(a =>{
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return { did, ...data};
      })))
      .subscribe( (res: Array<any>) =>{
        console.log(res);
        if(res.length > 0)
          this.userData.education = res;
        else  
          this.userData.education = [];
      });

    this.api.getCandidateWorkExperienceById(this.canId)
    .pipe(map(actions => actions.map(a =>{
      const data = a.payload.doc.data();
      const did = a.payload.doc.id;
      return { did, ...data};
    })))
    .subscribe( (res: Array<any>) =>{
      console.log(res);
      if(res.length > 0)
        this.userData.workExp = res;
      else  
        this.userData.workExp = [];
    });

    this.api.getCandidateSkillsById(this.canId)
      .pipe(map(actions => actions.map(a =>{
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return { did, ...data};
      })))
      .subscribe( (res: Array<any>) =>{
        console.log(res);
        if(res.length > 0)
          this.userData.skills = res;
        else
          this.userData.skills = [];
      });

    this.api.getCandidateAwardsById(this.canId)
    .pipe(map(actions => actions.map(a =>{
      const data = a.payload.doc.data();
      const did = a.payload.doc.id;
      return { did, ...data};
    })))
    .subscribe( (res: Array<any>) =>{
      console.log(res);
      if(res.length > 0)
        this.userData.awards = res;
      else
        this.userData.awards = [];23
    });

    this.api.getUserProfileDataById(this.canId)
      .pipe(map(actions => actions.map(a =>{
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return { did, ...data};
      })))
      .subscribe( (res: Array<any>) =>{
        console.log(res);
        if(res.length > 0)
          this.userData.profile = res[0];
      });

    // this.canSer.getEducationById(this.canId).subscribe(res => {
    //   // this.userData.education = res;
    //   this.canSer.getWorkById(this.canId).subscribe(res1 => {
    //     // this.userData.workExp = res1;
    //     this.canSer.getSkillsById(this.canId).subscribe(res2 => {
    //       // this.userData.skills = res2;
    //       this.canSer.getAwardsById(this.canId).subscribe(res3 => {
    //         // this.userData.awards = res3;
    //         this.canSer.getProfileById(this.canId).subscribe(res4 => {
    //           this.userData.profile = res4;
    //           console.log(this.userData.skills[0].skillname);
    //         }, err => {
    //           console.log(err);
    //         });
    //       }, err => {
    //         console.log(err);
    //       });
    //     }, err3 => {
    //       console.log(err3);
    //     });
    //   }, err2 => {
    //     console.log(err2);
    //   });
    // }, err4 => {
    //   console.log(err4);
    // });
  }
  downloadPdf() {
    html2canvas(document.querySelector('#cv'), {scale: 2}).then(canvas => {
      const pdf = new jsPDF('p', 'pt', [canvas.width / 2, canvas.height / 2]);
      const imgData  = canvas.toDataURL('image/jpeg', 1.0);
      pdf.addImage(imgData, 0, 0, canvas.width / 2, canvas.height / 2);
      const name = 'cv';
      pdf.save(name + '.pdf');
    });
  }
  goBack() {
    this.loc.back();
  }
}
