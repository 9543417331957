import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CandidateService } from 'src/app/services/candidate.service';
import { UserProfileInterface } from 'src/app/interfaces/user-profile.interface';
import { UserInterface } from 'src/app/interfaces/user.interface';
import { Address } from 'angular-google-place';
// import { LocationService } from 'src/app/services/location.service';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-canditate-list',
  templateUrl: './canditate-list.component.html',
  styleUrls: ['./canditate-list.component.css']
})
export class CanditateListComponent implements OnInit {
  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
  setLanguage(lng){
    console.log(lng);
    if(lng === 'en'){
      localStorage.setItem('language','en');
    }
    else{
      localStorage.setItem('language','ger');
    }
    this.translate.use(lng);
  }
  getUser = {} as UserProfileInterface;
  users: Array<UserInterface> = [];
  usersProfiles: Array<UserProfileInterface> = [];
  constructor(private canSer: CandidateService, private router: Router, public translate: TranslateService) {
    if(localStorage.getItem('language')){
      translate.use(localStorage.getItem('language'));
    }
    else{
      translate.setDefaultLang('Portuguese');//2
      translate.addLangs(['English', 'Portuguese']);
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
    }
    // this.location.addressAutocompleteItems = [];
    // this.location.addressAutocomplete = {
    //   query: ''
    // }
   }
  userData = [];
  ngOnInit() {
    localStorage.setItem('canSingle', null);
    this.canSer.getUsers()
    .subscribe(res => {
      this.users = res;
      console.log(this.users);
      this.canSer.getUsersProfile()
      .subscribe(res1 => {
        this.usersProfiles = res1;
        this.users.forEach(user => {
          const index = this.usersProfiles.findIndex((item, i) => {
            if (item.userId) {
              return item.userId === user.userId;
            }
          });
          if (index !== -1) {
            this.userData.push({userProfile: this.usersProfiles[index], userData: user});
          } else {
            const userPro = {} as UserProfileInterface;
            this.userData.push({userProfile: userPro, userData: user});
          }
        });
      }, err => {
        console.log(err);
      });
    }, err => {
      console.log(err);
    });
  }

  getAddress(place: Address) {
    console.log('Address', place);
  }
  getFormattedAddress(event: any) {
      console.log(event);
  }

  moveSingleCan(id) {
    console.log(id);
    localStorage.setItem('canSingle', id.userProfile.userId);
    this.router.navigate(['/employer/dashboard/candidate-single']);
  }

  searchCandidate() {
    localStorage.setItem('canProId', this.getUser.fullname);
    this.router.navigate(['/employer/home/can-pro-search']);
  }

  // getLocations(){
  //   this.location.addressUpdateSearch();
  // }

  // addressItem(item){
  //   this.disableaddress = true;
  //   this.location.addressAutocomplete.query = item;
  //   this.location.addressChooseItem(item);
  //   this.getUser.address = item;
  // }

  // disableaddress=true;

  // pickupBlur(){
  //   if(this.location.addressAutocomplete.query.length === 0){
  //     this.disableaddress = true;
  //   }
  // }

  // pickupFocus(){
  //   this.disableaddress = false;
  // }


}
