import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private afs: AngularFirestore) { }

  getAllPackages(){
    return this.afs.collection('packages').snapshotChanges();
  }

  updateUser(id,data){
    return this.afs.doc('user/'+id).update(data);
  }

  getUserData(id){
    return this.afs.collection('user', ref => ref.where('userId','==',id)).snapshotChanges();
  }

  getEmployerProfileById(id){
    return this.afs.collection('employerProfile', ref => ref.where('userId','==',id)).snapshotChanges();
  }

  getAllEmployerProfile(){
    return this.afs.collection('employerProfile').snapshotChanges();
  }

  getUserProfile(id){
    return this.afs.collection('userProfile', ref => ref.where('userId','==',id)).snapshotChanges();
  }

  getAllCompanies(){
    return this.afs.collection('user', ref => ref.where('userType','==','employer')).snapshotChanges();
  }

  getAllCanidates(){
    return this.afs.collection('user', ref => ref.where('userType','==','candidate')).snapshotChanges();    
  }

  // Candidate Resume.

  getCandidateEducationById(id){
    return this.afs.collection('candidateEducation', ref => ref.where('userId','==',id)).snapshotChanges();
  }
  
  getCandidateWorkExperienceById(id){
    return this.afs.collection('candidateWorkExp', ref => ref.where('userId','==',id)).snapshotChanges();
  }

  getCandidateSkillsById(id){
    return this.afs.collection('candidateSkill', ref => ref.where('userId','==',id)).snapshotChanges();
  }

  getCandidateAwardsById(id){
    return this.afs.collection('candidateAwards', ref => ref.where('userId', '==', id)).snapshotChanges();
  }

  getUserProfileDataById(id){
    return this.afs.collection('userProfile', ref => ref.where('userId','==',id)).snapshotChanges();
  }

  getShortListedJobs(id){
    return this.afs.collection('shortlistedJob', ref => ref.where('candidateId','==',id)).snapshotChanges();
  }

  getAppliedJobs(id){
    return this.afs.collection('applyJob', ref => ref.where('candidateId','==',id)).snapshotChanges();
  }

  getJobsByCategories(category){
    return this.afs.collection('jobPost', ref => ref.where('categories','==',category)).snapshotChanges();
  }

  getJobById(id){
    return this.afs.doc('jobPost/'+id).valueChanges();
  }

  getFeaturedJobs(){
    return this.afs.collection('jobPost', ref => ref.where('featured','==',true)).snapshotChanges();
  }

  getRecentJobs(){
    return this.afs.collection('jobPost', ref => ref.limit(5)).snapshotChanges();
  }

  getAllJobs(){
    return this.afs.collection('jobPost').snapshotChanges();
  }

  getPostJobs(id){
    return this.afs.collection('jobPost', ref => ref.where('userId','==',id)).snapshotChanges();
  }

  // Add contact us

  addContactUs(data){
    return this.afs.collection('contact-us').add(data);
  }


  getCandidateShortListedJobs(id){
    return this.afs.collection('shortlistedJob', ref => ref.where('candidateId','==',id)).snapshotChanges();
  }

  deleteShortlistLife(id){
    return this.afs.doc('shortlistedJob/'+id).delete();
  }

  getEmployerFeaturedJobs(id){
    return this.afs.collection('jobPost', ref=> ref.where('userId','==',id).where('featured','==',true)).snapshotChanges();
  }

  getUsersByCountryAndCategories(country,category){
    return this.afs.collection('userProfile', ref => ref.where('country','==',country).where('categories','==',category)).snapshotChanges();
  }

}
