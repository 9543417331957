import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserInterface } from 'src/app/interfaces/user.interface';
import { NgFlashMessageService } from 'ng-flash-messages';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  disableButton = false;
  registerForm: FormGroup;
  userObj = {} as UserInterface;
  userTypeStatus = true;
  signUpStatus = false;
  itemsCollection;
  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
  setLanguage(lng){
    console.log(lng);
    if(lng === 'en'){
      localStorage.setItem('language','en');
    }
    else{
      localStorage.setItem('language','ger');
    }
    this.translate.use(lng);
  }
  constructor(private authService: AuthenticationService,
    private fb: FormBuilder,
    private ngFlashMessageService: NgFlashMessageService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public translate: TranslateService) {
      if(localStorage.getItem('language')){
        translate.use(localStorage.getItem('language'));
      }
      else{
        translate.setDefaultLang('Portuguese');//2
        translate.addLangs(['English', 'Portuguese']);
        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
      }
    this.registerForm = fb.group({
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)])],
      password: [null, Validators.compose([Validators.required])],
      name: [null, Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z \'\-]+$/)])]
    });
  }
  ngOnInit() {
    console.log(this.userTypeStatus);
  }
  registerUser(data: FormData) {
    if (this.registerForm.valid && !this.userTypeStatus) {
      this.spinner.show();
      this.disableButton = true;
      this.authService.register(this.userObj, data['email'], data['password']).then((status: any) => {
        this.signUpStatus = true;
        this.authService.afAuth.user.subscribe(x => {
          // console.log(x);
          this.itemsCollection = this.authService.afs.collection('user');
          if (x)
            if (x.emailVerified === false) {
              x.sendEmailVerification()
                .then(() => {
                  this.userObj.userId = status.user.uid;
                  // console.log(userData);
                  this.itemsCollection.add(this.userObj);
                  console.log('I am registered');
                })
                .catch(err => {
                  console.log('Error: ', err);
                });
            }
        });
        swal.fire('Email Verification', 'Email yet to be verifed. Please check your email', 'success');
        this.router.navigate(['/']);
        this.spinner.hide();
        this.ngFlashMessageService.showFlashMessage({
          messages: ['Email yet to be verifed. Please check your email'],
          dismissible: true,
          timeout: 5000,
          type: 'success'
        });
      }, err => {
        this.ngFlashMessageService.showFlashMessage({
          messages: [err.message]
          ,
          dismissible: true,
          timeout: 5000,
          type: 'danger'
        });
        this.spinner.hide();
      });
    }
  }
  selectUser(usertype) {
    this.userTypeStatus = false;
    this.userObj.userType = usertype;
  }
}
