import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployerService } from 'src/app/services/employer.service';
import { JobInterface } from 'src/app/interfaces/job.interface';
import { EmployerProfileInterface } from 'src/app/interfaces/employer-profile.interface';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-can-emp-single',
  templateUrl: './can-emp-single.component.html',
  styleUrls: ['./can-emp-single.component.css']
})
export class CanEmpSingleComponent implements OnInit {
  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
    setLanguage(lng){
      console.log(lng);
      if(lng === 'en'){
        localStorage.setItem('language','en');
      }
      else{
        localStorage.setItem('language','ger');
      }
      this.translate.use(lng);
    }
  empId = localStorage.getItem('empSingle');
  empProfile = {} as EmployerProfileInterface;
  jobs: Array<JobInterface> = [];
  constructor(private router: Router, private empSer: EmployerService, public translate: TranslateService) { 
    if(localStorage.getItem('language')){
          translate.use(localStorage.getItem('language'));
        }
        else{
          translate.setDefaultLang('Portuguese');//2
          translate.addLangs(['English', 'Portuguese']);
          const browserLang = translate.getBrowserLang();
          translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
        }}

  ngOnInit() {
    localStorage.setItem('jobSingleId', null);
    this.getEmp();
    this.getJobs();
  }
  canJobSingle(index: number) {
    const uid = this.jobs[index].jobId;
    localStorage.setItem('jobSingleId', uid);
    this.router.navigate(['/candidate/home/job-single']);
  }
  getEmp() {
    this.empSer.getEmpById(this.empId).subscribe(res => {
      this.empProfile = res[0];
      console.log(this.empProfile);
    }, err => {
      console.log(err);
    });
  }
  getJobs() {
    this.empSer.getJobsById(this.empId).subscribe(res => {
      this.jobs = res;
    }, err => {
      console.log(err);
    });
  }
}
