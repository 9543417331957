import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-can-job-list',
  templateUrl: './can-job-list.component.html',
  styleUrls: ['./can-job-list.component.css']
})
export class CanJobListComponent implements OnInit {

  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
  setLanguage(lng){
    console.log(lng);
    if(lng === 'en'){
      localStorage.setItem('language','en');
    }
    else{
      localStorage.setItem('language','ger');
    }
    this.translate.use(lng);
  }
  constructor(private router: Router, public translate: TranslateService) { 
    if(localStorage.getItem('language')){
          translate.use(localStorage.getItem('language'));
        }
        else{
          translate.setDefaultLang('Portuguese');//2
          translate.addLangs(['English', 'Portuguese']);
          const browserLang = translate.getBrowserLang();
          translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
        }}

  ngOnInit() {
  }

  canJobSingle(data) {
    this.router.navigate(['candidate/home/candidate-single']);
  }
}
