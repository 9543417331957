import { Component, OnInit } from '@angular/core';
import { EmployerService } from 'src/app/services/employer.service';
import { JobInterface } from 'src/app/interfaces/job.interface';
import { EmployerProfileInterface } from 'src/app/interfaces/employer-profile.interface';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-job-single',
  templateUrl: './job-single.component.html',
  styleUrls: ['./job-single.component.css']
})
export class JobSingleComponent implements OnInit {
  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
  setLanguage(lng){
    console.log(lng);
    if(lng === 'en'){
      localStorage.setItem('language','en');
    }
    else{
      localStorage.setItem('language','ger');
    }
    this.translate.use(lng);
  }
  constructor(private empSer: EmployerService, public translate: TranslateService) {
    if(localStorage.getItem('language')){
      translate.use(localStorage.getItem('language'));
    }
    else{
      translate.setDefaultLang('Portuguese');//2
      translate.addLangs(['English', 'Portuguese']);
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
    }
   }
  jobID = JSON.parse(localStorage.getItem('jobSingleId'));
  jobSingle = {} as JobInterface;
  ngOnInit() {
    console.log(this.jobID);
    this.empSer.getJobSingle(this.jobID).subscribe(res => {
      this.jobSingle = res;
      console.log(this.jobSingle);
    }, err => {
      console.log(err);
    });
  }
}
