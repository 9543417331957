import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplyJobInterface } from 'src/app/interfaces/apply-job.interface';
import { CandidateService } from 'src/app/services/candidate.service';
import swal from 'sweetalert2';
import { ApiService } from 'src/app/services/api.service';
import { map } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-can-shortlist',
  templateUrl: './can-shortlist.component.html',
  styleUrls: ['./can-shortlist.component.css']
})
export class CanShortlistComponent implements OnInit {
  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
    setLanguage(lng){
      console.log(lng);
      if(lng === 'en'){
        localStorage.setItem('language','en');
      }
      else{
        localStorage.setItem('language','ger');
      }
      this.translate.use(lng);
    }
  username = JSON.parse(localStorage.getItem('userData')).name;
  shortListedJob: Array<ApplyJobInterface> = [];
  p = 1;
  constructor(private router: Router, private canSer: CandidateService, private api: ApiService, public translate: TranslateService) {
    if(localStorage.getItem('language')){
          translate.use(localStorage.getItem('language'));
        }
        else{
          translate.setDefaultLang('Portuguese');//2
          translate.addLangs(['English', 'Portuguese']);
          const browserLang = translate.getBrowserLang();
          translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
        } }

  ngOnInit() {
    // this.getShortListedJobs();
    const users = JSON.parse(localStorage.getItem('user'));
    this.api.getShortListedJobs(users.uid)
      .pipe(map( actions => actions.map(a =>{
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return {did, ...data};
      })))
      .subscribe( (res: Array<any>) =>{
        this.shortListedJob = res;
      });
  }
  getShortListedJobs() {
   this.canSer.getShortlistedJobs().subscribe(res => {
    this.shortListedJob = res;
   }, err => {
     console.log(err);
   });
  }
  canJobSingle(index: number) {
    const uid = this.shortListedJob[index].jobId;
    localStorage.setItem('jobSingleId', uid);
    this.router.navigate(['/candidate/home/job-single',{
      type: 'shortlist',
      id: uid
    }]);
  }
  deleteShort(index: number) {
    this.canSer.deleteShortlistedJob(this.shortListedJob[index].candidateId, this.shortListedJob[index].jobId).subscribe(res => {
      swal.fire('Deleted', 'Successfully deleted', 'success');
    }, err => {
      console.log(err);
    });
  }
}
