import { Component, OnInit } from '@angular/core';
import { EmployerService } from 'src/app/services/employer.service';
import { JobInterface } from 'src/app/interfaces/job.interface';
import { identifierModuleUrl } from '@angular/compiler';
import { ApplyJobInterface } from 'src/app/interfaces/apply-job.interface';
import { CandidateService } from 'src/app/services/candidate.service';
import swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { map } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-can-job-single',
  templateUrl: './can-job-single.component.html',
  styleUrls: ['./can-job-single.component.css']
})
export class CanJobSingleComponent implements OnInit {
  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
    setLanguage(lng){
      console.log(lng);
      if(lng === 'en'){
        localStorage.setItem('language','en');
      }
      else{
        localStorage.setItem('language','ger');
      }
      this.translate.use(lng);
    }
  constructor(private empSer: EmployerService, private canSer: CandidateService, private route: ActivatedRoute, private api: ApiService, private router: Router, public translate: TranslateService) { 
    if(localStorage.getItem('language')){
          translate.use(localStorage.getItem('language'));
        }
        else{
          translate.setDefaultLang('Portuguese');//2
          translate.addLangs(['English', 'Portuguese']);
          const browserLang = translate.getBrowserLang();
          translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
        }}
  jobID:string;
  jobSingle = {} as JobInterface;
  applyJobObj = {} as ApplyJobInterface;
  user = JSON.parse(localStorage.getItem('user'));

  work=[];
  education=[];
  userData=[];

  ngOnInit() {
    this.jobID = localStorage.getItem('jobSingleId');
    console.log(this.jobID);
    this.route.params.subscribe( data =>{
      if(data.type === 'foryou'){
        this.api.getJobById(this.jobID)
          .subscribe((res: any) =>{
            this.jobSingle = res;
          })
      }
      else if(data.type === 'shortlist'){
        this.api.getJobById(data.id)
        .subscribe((res: any) =>{
          this.jobSingle = res;
        })
      }
      else{
        this.canSer.getJobById(this.jobID).subscribe(res => {
          this.jobSingle = res;
          console.log(res);
        }, err => {
          console.log(err);
        });
      }
    });

    this.api.getCandidateEducationById(localStorage.getItem('uid'))
      .pipe(map(actions => actions.map(a =>{
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return {did, ...data};
      })))
      .subscribe( (res: Array<any>) =>{
        this.education = res;
      });

    this.api.getCandidateWorkExperienceById(localStorage.getItem('uid'))
      .pipe(map(actions => actions.map(a =>{
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return {did, ...data};
      })))
      .subscribe( (res: Array<any>) =>{
        this.work = res;
      });

    this.api.getUserProfile(localStorage.getItem('uid'))
      .pipe(map(actions => actions.map(a =>{
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return {did, ...data};
      })))
      .subscribe( (res: Array<any>) =>{
        this.userData = res;
      });

  }
  applyJob() {
    if(this.education.length === 0 || this.work.length === 0){
      alert('Please complete your resume first.');
      this.router.navigate(['candidate/dashboard/resume']);
      return;
    }
    if(this.userData.length === 0){
      alert('Please complete your profile first.');
      this.router.navigate(['candidate/dashboard/profile']);
      return;
    }

    try{
      const date = new Date();
    this.applyJobObj.job_title = this.jobSingle.job_title;
    this.applyJobObj.job_type = this.jobSingle.job_type;
    this.applyJobObj.address = this.jobSingle.address;
    this.applyJobObj.applied_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    this.applyJobObj.jobId = this.jobID;
    this.applyJobObj.empId = this.jobSingle.userId;
    this.applyJobObj.candidateId = this.user.uid;
    }
    catch(e) {}
    this.canSer.applyJob(this.applyJobObj).subscribe( res => {
      const data  = JSON.parse(JSON.stringify(res));
      if (data.success) {
        swal.fire('Success', data.message, 'success');
      } else {
        swal.fire('Failure', data.message, 'error');
      }
    }, err => {
        console.log(err);
    });
  }
  shortlistJob() {
    try{
      const date = new Date();
    this.applyJobObj.job_title = this.jobSingle.job_title;
    this.applyJobObj.job_type = this.jobSingle.job_type;
    this.applyJobObj.address = this.jobSingle.address;
    this.applyJobObj.applied_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    this.applyJobObj.jobId = this.jobID;
    this.applyJobObj.empId = this.jobSingle.userId;
    this.applyJobObj.candidateId = this.user.uid;
    }
    catch(E){}
    this.canSer.shortlistJob(this.applyJobObj).subscribe( res => {
      const data  = JSON.parse(JSON.stringify(res));
      if (data.success) {
        swal.fire('Success', data.message, 'success');
      } else {
        swal.fire('Failure', data.message, 'error');
      }
    }, err => {
        console.log(err);
    });
  }
}
