import { JobInterface } from './../../interfaces/job.interface';
import { EmployerService } from './../../services/employer.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  encapsulation: ViewEncapsulation.None
})
export class HomepageComponent implements OnInit {

  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';

  imageObject: Array<object> = [{
    image: 'assets/images/partner1.png',
    thumbImage: 'assets/images/partner1.png',
    title: ''
},{
    image: 'assets/images/partner2.png',
    thumbImage: 'assets/images/partner2.png',
    title: ''
  },{
    image: 'assets/images/partner3.jpg',
    thumbImage: 'assets/images/partner3.jpg',
    title: ''
  },{
    image: 'assets/images/partner4.png',
    thumbImage: 'assets/images/partner4.png',
    title: ''
  },{
    image: 'assets/images/partner5.png',
    thumbImage: 'assets/images/partner5.png',
    title: ''
  },{
    image: 'assets/images/partner6.png',
    thumbImage: 'assets/images/partner6.png',
    title: ''
  }
];

  constructor(private router: Router, private empSer: EmployerService, public translate: TranslateService) { 
    translate.setDefaultLang('ger');//2
    translate.use(localStorage.getItem('language') ? localStorage.getItem('language') : 'ger');
  }
  jobs: Array<JobInterface> = [];
  jobPosted = 0;
  ngOnInit() {
    // this.getJobs();
  }
  checkClick(num) {
    if (num === '1') {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/register']);
    }
  }
  // getJobs() {
  //   this.empSer.getJobsForCompany().subscribe(res => {
  //     this.jobs = res;
  //     this.jobPosted = this.jobs.length;
  //   }, err => {
  //     console.log(err);
  //   });
  // }

  setLanguage(lng){
    if(lng === 'en'){
      localStorage.setItem('language','en');
    }
    else{
      localStorage.setItem('language','ger');
    }
    this.translate.use(lng);
  }

  TflagPort(){
    console.log("tflgPort");
    localStorage.setItem('language','ger');
    this.translate.use('ger');
  }
  
  TflagEng(){
    console.log("tflgEng");
    localStorage.setItem('language','en');
    this.translate.use('en');

  }

}
