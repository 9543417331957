import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgFlashMessageService } from 'ng-flash-messages';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  loginform: FormGroup;
  userTypeStatus = true;
  userType = '';
  loginError = false;

  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
  setLanguage(lng){
    console.log(lng);
    if(lng === 'en'){
      localStorage.setItem('language','en');
    }
    else{
      localStorage.setItem('language','ger');
    }
    this.translate.use(lng);
  }
  
  constructor(
    private authService: AuthenticationService, private fb: FormBuilder, public router: Router,
    private ngFlashMessageService: NgFlashMessageService, private spinner: NgxSpinnerService, public translate: TranslateService) {
    if(localStorage.getItem('language')){
        translate.use(localStorage.getItem('language'));
      }
      else{
        translate.setDefaultLang('Portuguese');//2
        translate.addLangs(['English', 'Portuguese']);
        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
      }
    this.loginform = fb.group({
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)])],
      password: [null, Validators.compose([Validators.required])]
    });
  }
  ngOnInit() {
  }
  selectUser(userType: string) {
    this.userType = userType;
    this.userTypeStatus = false;
  }
  loginUser(data: FormData) {
    if (this.loginform.valid && !this.userTypeStatus) {
      this.spinner.show();
      this.authService.login(data['email'], data['password']).then(user => {
        this.authService.enablePersistence();
        console.log('i have called login service method');
        try {
          if (user.code === 'auth/user-not-found') {
            this.ngFlashMessageService.showFlashMessage({
              messages: ['User not found.'],
              dismissible: true,
              timeout: 5000,
              type: 'danger'
            });
            this.spinner.hide();
            return;
          }
        }
        catch (e) { }
        if (user.emailVerified === false) {
          this.authService.afAuth.user.subscribe(x => {
          x.sendEmailVerification()
          }
          );
          this.ngFlashMessageService.showFlashMessage({
            messages: ['Email not verified. Please check your email'],
            dismissible: true,
            timeout: 5000,
            type: 'danger'
          });
          this.spinner.hide();
          return;
        }
        this.authService.getUserData(user.uid).subscribe(us => {
          console.log('i have getuserdata service method');
          this.authService.setUserData(us[0]);
          // localStorage.setItem('userData', us[0]);
          if (this.userType === us[0].userType) {
            this.ngFlashMessageService.showFlashMessage({
              messages: ['You have been successfuly logged in'],
              dismissible: true,
              timeout: 5000,
              type: 'success'
            });
            try {
              if (us[0].userType === 'candidate') {
                localStorage.setItem('uid', us[0].userId);
                this.router.navigate(['/candidate/dashboard']);
              }
              else if (us[0].userType === 'employer' && !us[0].package) {
                localStorage.setItem('uid', us[0].userId);
                this.router.navigate(['/employer/dashboard/packages']);
              }
              else if (us[0].userType === 'employer' && us[0].package.endDate < this.convertDate(new Date())) {
                localStorage.setItem('uid', us[0].userId);
                this.router.navigate(['/employer/dashboard']);
              }
              else if (us[0].userType === 'employer') {
                localStorage.setItem('uid', us[0].userId);
                this.router.navigate(['/employer/dashboard/packages']);
              }

            }
            catch (e) { }
          } else {
            this.loginError = true;
            this.ngFlashMessageService.showFlashMessage({
              messages: ['Invalid Credentials Provided'],
              dismissible: true,
              timeout: 5000,
              type: 'danger'
            });
          }
          this.spinner.hide();
        });
      });
    }
  }

  convertDate(Today) {
    return `${Today.getFullYear()}-${(Today.getMonth() + 1) < 10 ? ('0' + (Today.getMonth() + 1)) : (Today.getMonth() + 1)}-${(Today.getDate() < 10) ? ('0' + (Today.getDate())) : Today.getDate()}`
  }
}


