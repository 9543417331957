import { Component, OnInit } from '@angular/core';
import { JobInterface } from 'src/app/interfaces/job.interface';
import { EmployerService } from 'src/app/services/employer.service';
import swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
// import { stringify } from '@angular/core/src/render3/util';
import { Address } from 'angular-google-place';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';
import { map } from 'rxjs/operators';
// import { LocationService } from 'src/app/services/location.service';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { EmployerProfileInterface } from 'src/app/interfaces/employer-profile.interface';
import { HttpClient,  HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';


@Component({
  selector: 'app-post-new',
  templateUrl: './post-new.component.html',
  styleUrls: ['./post-new.component.css']
})
export class PostNewComponent implements OnInit {
  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
  setLanguage(lng){
    console.log(lng);
    if(lng === 'en'){
      localStorage.setItem('language','en');
    }
    else{
      localStorage.setItem('language','ger');
    }
    this.translate.use(lng);
  }
  userProfile = {
    fullname: ''
} as EmployerProfileInterface;

companyName='';
  skills: Array<string> = [];
  jobForm: FormGroup;
  featured = 'no';
  userData;
  constructor(private empSer: EmployerService, private fb: FormBuilder, private spinner: NgxSpinnerService, private api : ApiService, private router: Router, public translate: TranslateService, private http: HttpClient) {
    if(localStorage.getItem('language')){
      translate.use(localStorage.getItem('language'));
    }
    else{
      translate.setDefaultLang('Portuguese');//2
      translate.addLangs(['English', 'Portuguese']);
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
    }

    this.empSer.getEmpProfile().subscribe(res => {
      console.log(res);
      if (res.length > 0) {
        this.userProfile = res[0];
        this.companyName = this.userProfile.fullname;
      }
      console.log(this.userProfile.country);
    });
    // this.jobForm = fb.group({
    //   job_title: [null, Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z \'\-]+$/)])],
    //   address: [null, Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z \'\-]+$/)])],
    //   lat: [null, Validators.compose([Validators.required])],
    //   lon: [null, Validators.compose([Validators.required])]
    // });
    // this.location.addressAutocompleteItems = [];
    // this.location.addressAutocomplete = {
    //   query: ''
    // }
   }
  postJob = {
    job_title: '',
    description: '',
    job_type: '',
    categories: '',
    // offered_sal: '',
    career_level: '',
    experience: '',
    // gender: '',
    industry: '',
    qualification: '',
    skills: [],
    country: '',
    city: '',
    address: '',
    featured: false
  } as JobInterface;
  ngOnInit() {
    this.api.getUserData(localStorage.getItem('uid'))
      .pipe(map(actions => actions.map(a =>{
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return {did, ...data};
      })))
      .subscribe ((res : Array<any>) =>{
        if(res.length > 0)
          this.userData  = res[0];
      });

    this.api.getEmployerProfileById(localStorage.getItem('uid'))
    .pipe(map(actions => actions.map(a =>{
      const data = a.payload.doc.data();
      const did = a.payload.doc.id;
      return {did, ...data};
    })))
    .subscribe ((res : Array<any>) =>{
      if(res.length === 0){
        alert('Please complete your profile first');
        this.router.navigate(['employer/dashboard/profile']);
      }
    });
  }

  getAddress(place: Address) {
    console.log('Address', place);
  }
  getFormattedAddress(event: any) {
      console.log(event);
      this.postJob.city = event.city;
      this.postJob.country = event.country;
      this.postJob.lat = event.lat;
      this.postJob.lon = event.lng;
  }
  addSkill(event) {
    console.log(event);
    this.skills.push(event);
    event = '';
  }
  skillRemove(index) {
    this.skills.splice(index, 1);
  }
  submitJob() {
    try{
      if(this.featured === 'no'){
        this.postJob.featured = false;
        if(this.userData.package.normal > 0 )
          this.userData.package.normal = this.userData.package.normal - 1;
        else
          return;
      }
    else{
      this.postJob.featured = true;
      if(this.userData.package.featured > 0)
        this.userData.package.featured = this.userData.package.featured - 1;
      else
        return;
    }
    }
    catch(e){}
    for (var key in this.postJob) {
      if (this.postJob[key] === null || this.postJob[key] === ""){
        console.log(this.postJob[key]);
        console.log(this.postJob);
        alert('please fill all fields');
        return;
      }
    }
    this.api.updateUser(this.userData.did, this.userData);
    const user = JSON.parse(localStorage.getItem('user'));
    this.postJob.userId = user.uid;
    this.postJob.skills = this.skills;
    this.postJob.status = 'Active';
    let date = new Date();
    this.postJob.created_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    this.postJob.dead_line = date.getFullYear() + '-' + (date.getMonth()+2) + '-' + date.getDate();;
    // console.log(this.postJob);
    this.empSer.addJob(this.postJob).subscribe(res => {
      this.spinner.show();
      setTimeout(() => {
        this.spinner.hide();
        this.sendEmail();
      }, 2000);

    }, err => {
      console.log(err);
    });
  }

  sendEmail(){
    this.spinner.show();
    // console.log(this.postJob.country,this.postJob.categories);
    this.api.getUsersByCountryAndCategories(this.postJob.country,this.postJob.categories)
      .pipe(map(actions => actions.map(a =>{
        const data: any = a.payload.doc.data();
        const did = a.payload.doc.id;
        return {did, ...data};
      })))
      .subscribe ((res : Array<any>) =>{
        if(res.length > 0){
          let emails = '';
          res.forEach(a =>{
            if(emails > '' || emails.length > 0){
              emails = emails+', ' +a.email;
            }
            else{
              emails = a.email;
            }
          });

          if(emails.length > 0){
            this.postMail(emails,this.postJob.categories,this.postJob.job_title)
              .subscribe((email: any) =>{
                console.log(email);
                if(email === 'sent'){
                  this.postJob = {} as JobInterface;
                  this.skills = [];
                  swal.fire('Post a Job', 'Succesfully Posted', 'success');
                  this.spinner.hide();
                }
                else{
                  this.postJob = {} as JobInterface;
                  this.skills = [];
                  swal.fire('Post a Job', 'Succesfully Posted', 'success');
                  this.spinner.hide();
                }
              });
          }
          else{
            this.postJob = {} as JobInterface;
            this.skills = [];
            swal.fire('Post a Job', 'Succesfully Posted', 'success');
            this.spinner.hide();
          }

        }
        else{
          this.postJob = {} as JobInterface;
          this.skills = [];
          swal.fire('Post a Job', 'Succesfully Posted', 'success');
          this.spinner.hide();
        }
      });
  }

  postMail(emails,category,jobName){
    let url = 'https://us-central1-empregos-a969c.cloudfunctions.net/deleteUser/sendMail';
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')


    return this.http
    .post(url,JSON.stringify({
     emails: emails,
     companyName: this.companyName,
     category: category,
     jobName: jobName
    }) ,{ headers: headers });  
  }


  
  // getLocations(){
  //   this.location.addressUpdateSearch();
  // }

  // addressItem(item){
  //   this.disableaddress = true;
  //   this.location.addressAutocomplete.query = item;
  //   this.location.addressChooseItem(item);
  //   this.postJob.address = item;
  // }

  // disableaddress=true;

  // pickupBlur(){
  //   if(this.location.addressAutocomplete.query.length === 0){
  //     this.disableaddress = true;
  //   }
  // }

  // pickupFocus(){
  //   this.disableaddress = false;
  // }
}
