import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserInterface } from 'src/app/interfaces/user.interface';
import { UserProfileInterface } from 'src/app/interfaces/user-profile.interface';
import { ApplyJobInterface } from 'src/app/interfaces/apply-job.interface';
import { CandidateService } from './../../../services/candidate.service';
import { EmployerService } from 'src/app/services/employer.service';
import { TranslateService } from '@ngx-translate/core';
import { EmployerProfileInterface } from 'src/app/interfaces/employer-profile.interface';
import { ApiService } from 'src/app/services/api.service';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.css']
})
export class ResumeComponent implements OnInit {
  language = localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
  totalJobs = [];
  setLanguage(lng) {
    console.log(lng);
    if (lng === 'en') {
      localStorage.setItem('language', 'en');
    }
    else {
      localStorage.setItem('language', 'ger');
    }
    this.translate.use(lng);
    this.empSer.getEmpProfile().subscribe(res => {
      console.log(res);
      if (res.length > 0) {
        this.userProfile = res[0];
        this.companyName = this.userProfile.fullname;
      }
      console.log(this.userProfile.country);
    });

  }
  //user = localStorage.getItem('users');
  // users: Array<UserInterface> = [];
  appliedJobs: Array<ApplyJobInterface> = [];
  usersProfiles: Array<UserProfileInterface> = [];
  user = {} as UserProfileInterface;
  constructor(private router: Router, private empSer: EmployerService, private canSer: CandidateService, public translate: TranslateService, private api: ApiService) {
    if (localStorage.getItem('language')) {
      translate.use(localStorage.getItem('language'));
    }
    else {
      translate.setDefaultLang('Portuguese');//2
      translate.addLangs(['English', 'Portuguese']);
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
    }
    this.empSer.getEmpProfile().subscribe(res => {
      console.log(res);
      if (res.length > 0) {
        this.userProfile = res[0];
        this.companyName = this.userProfile.fullname;
      }
      console.log(this.userProfile.country);
    });

  }
  userData = [];
  userProfile = {
    fullname: ''
  } as EmployerProfileInterface;

  companyName = '';
  ngOnInit() {
    //getProfileById
    const users = JSON.parse(localStorage.getItem('user'));

    this.api.getPostJobs(users.uid)
      .pipe(map(actions => actions.map(a => {
        const data: any = a.payload.doc.data();
        const did = a.payload.doc.id;
        return { did, ...data };
      })))
      .subscribe((jobs: Array<any>) => {
        console.log(jobs);
        this.totalJobs = jobs;
      })
    this.empSer.getAppliedJobs(users.uid)
      .subscribe(res => {
        console.log(res);
        this.appliedJobs = res;
        this.appliedJobs.forEach((item) => {
          this.canSer.getProfileById(item.candidateId).subscribe(res1 => {
            this.user = res1;
            this.usersProfiles.push(this.user);
          });
        });
        console.log(this.usersProfiles);
      });
  }

  getUserProfile(jobId){
    let x = this.appliedJobs.filter(data => data.jobId === jobId);
    if(x.length > 0){
      let candidates = []; 
      x.forEach(a =>{
        candidates.push(a.candidateId);
      });
      let user = this.usersProfiles.filter(data => candidates.indexOf(data.userId) > -1);
      if(user.length > 0){
        return user;
      }
      else{
        return [];
      }
    }
    else{
      return [];
    }
  }

  empCanSin(canId) {
    localStorage.setItem('canSingle', canId);
    this.router.navigate(['/employer/dashboard/candidate-single']);
  }

  downloadCv(userId) {
    localStorage.setItem('canSingle', userId);
    this.router.navigate(['/candidate/download-cv']);
  }
}
