import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CandidateService } from 'src/app/services/candidate.service';
import { UserInterface } from 'src/app/interfaces/user.interface';
import { UserProfileInterface } from 'src/app/interfaces/user-profile.interface';
import { Address } from 'angular-google-place';
import { ApiService } from 'src/app/services/api.service';
import { map } from 'rxjs/operators';
import { LocationService } from 'src/app/services/location.service';
import { TranslateService } from '@ngx-translate/core';
// import { LocationService } from 'src/app/services/location.service';
declare var google;

@Component({
  selector: 'app-can-list',
  templateUrl: './can-list.component.html',
  styleUrls: ['./can-list.component.css']
})
export class CanListComponent implements OnInit {
  language = localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
  setLanguage(lng) {
    console.log(lng);
    if (lng === 'en') {
      localStorage.setItem('language', 'en');
    }
    else {
      localStorage.setItem('language', 'ger');
    }
    this.translate.use(lng);
  }
  getUser = {} as UserProfileInterface;
  users: Array<UserInterface> = [];
  usersProfiles: Array<UserProfileInterface> = [];
  userProfile = {
    address: ''
  }
  constructor(private canSer: CandidateService, private router: Router, private api: ApiService, public translate: TranslateService) {

    if (localStorage.getItem('language')) {
      translate.use(localStorage.getItem('language'));
    }
    else {
      translate.setDefaultLang('Portuguese');//2
      translate.addLangs(['English', 'Portuguese']);
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
    }// this.location.addressAutocompleteItems = [];
    // this.location.addressAutocomplete = {
    //   query: ''
    // }
  }
  userData = [];
  city = '';
  userInfo;

  ngOnInit() {

    this.api.getUserProfile(localStorage.getItem('uid'))
      .pipe(map(Actions => Actions.map(a => {
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return { did, ...data };
      })))
      .subscribe((res: Array<any>) => {
        if (res.length > 0) {
          this.userInfo = res[0];
          console.log(this.userInfo);
        }
      });

    this.canSer.getUsers()
      .subscribe(res => {
        this.users = res;
        console.log(this.users);
        this.canSer.getUsersProfile()
          .subscribe(res1 => {
            this.usersProfiles = res1;
            console.log(this.usersProfiles);
            this.users.forEach(user => {
              const index = this.usersProfiles.findIndex((item, i) => {
                if (item.userId) {
                  return item.userId === user.userId;
                }
              });
              console.log(index);
              if (index !== -1) {
                this.userData.push({ userProfile: this.usersProfiles[index], userData: user });
                console.dir(this.userData);
              } else {
                const userPro = {} as UserProfileInterface;
                this.userData.push({ userProfile: userPro, userData: user });
              }
            });
          }, err => {
            console.log(err);
          });
      }, err => {
        console.log(err);
      });
  }

  getAddress(place: Address) {
    console.log('Address', place);
  }
  getFormattedAddress(event: any) {
    console.log(event);
    if (event.city) {
      this.city = event.city;
    }
  }

  moveSingleCan(id) {
    localStorage.setItem('canSingle', id);
    console.log(id);
    this.router.navigate(['/candidate/home/candidate-single']);
  }

  searchCandidate() {

    localStorage.setItem('canProId', this.getUser.fullname);
    localStorage.setItem('canProCity', this.city);
    this.router.navigate(['/candidate/home/can-profile-search']);
  }



  // getLocations(){
  //   this.location.addressUpdateSearch();
  // }

  // addressItem(item){
  //   this.disableaddress = true;
  //   this.location.addressAutocomplete.query = item;
  //   this.location.addressChooseItem(item);
  //   this.userProfile.address = item;
  // }

  // disableaddress=true;

  // pickupBlur(){
  //   if(this.location.addressAutocomplete.query.length === 0){
  //     this.disableaddress = true;
  //   }
  // }

  // pickupFocus(){
  //   this.disableaddress = false;
  // }
}
