import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-can-dash-sidebar',
  templateUrl: './can-dash-sidebar.component.html',
  styleUrls: ['./can-dash-sidebar.component.css']
})
export class CanDashSidebarComponent implements OnInit {

  constructor(private auth: AuthenticationService, public translate: TranslateService) {
    if(localStorage.getItem('language')){
      translate.use(localStorage.getItem('language'));
    }
    else{
      translate.setDefaultLang('ger');//2
      translate.use(localStorage.getItem('language') ? localStorage.getItem('language') : 'ger');
    }
  }

  ngOnInit() {
  }
  signOut() {
    this.auth.logout();
  }
}
