import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import {TranslateService} from '@ngx-translate/core';
import { EmployerProfileInterface } from 'src/app/interfaces/employer-profile.interface';

import { EmployerService } from 'src/app/services/employer.service';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.css']
})
export class PackagesComponent implements OnInit {
  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
  setLanguage(lng){
    console.log(lng);
    if(lng === 'en'){
      localStorage.setItem('language','en');
    }
    else{
      localStorage.setItem('language','ger');
    }
    this.translate.use(lng);
  }
  packages=[];
  userData;
  public payPalConfig?: IPayPalConfig;
  showSuccess = false;

  constructor(private api: ApiService, private empSer: EmployerService, public translate: TranslateService) { 
    if(localStorage.getItem('language')){
      translate.use(localStorage.getItem('language'));
    }
    else{
      translate.setDefaultLang('Portuguese');//2
      translate.addLangs(['English', 'Portuguese']);
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
    }
    this.empSer.getEmpProfile().subscribe(res => {
      console.log(res);
      if (res.length > 0) {
        this.userProfile = res[0];
        this.companyName = this.userProfile.fullname;
      }
      console.log(this.userProfile.country);
    });
  }
  userProfile = {
    fullname: ''
} as EmployerProfileInterface;

companyName='';
  ngOnInit() {
    this.api.getAllPackages()
      .pipe(map(actions => actions.map(a =>{
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return {did, ...data};
      })))
        .subscribe( (res: Array<any>) =>{
          this.packages = res.sort((a,b):any => {
            if(a.days<b.days){
              return -1;
            }
            else{
              return 1;
            }
          });
        });
    
    this.api.getUserData(localStorage.getItem('uid'))
    .pipe(map(actions => actions.map(a =>{
      const data = a.payload.doc.data();
      const did = a.payload.doc.id;
      return {did, ...data};
    })))
    .subscribe((res:Array<any>) =>{
      if(res.length > 0)
        this.userData = res[0];
    })
    
  }

  buy(item){
    this.initConfig(item);
  }

  convertDate(Today){
    return `${Today.getFullYear()}-${(Today.getMonth() + 1) < 10 ? ('0' + (Today.getMonth() + 1)) : (Today.getMonth() + 1)}-${(Today.getDate() < 10) ? ('0' + (Today.getDate())) : Today.getDate()}`
  }

  private initConfig(item: any): void {
    let price = item.price;
    this.payPalConfig = {
    currency: 'USD',
    clientId: 'AR3aWW0FRLdgtVvvjTDz2ZsiBmh8drpRtEtULiWm7bvSvkjtKaOfQ-9F8nNtwdBN_MbONVpgtUmJexVS',
    createOrderOnClient: (data) => <ICreateOrderRequest>{
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code: 'USD',
            value: price.toFixed(2).toString(),
            breakdown: {
              item_total: {
                currency_code: 'USD',
                value: price.toFixed(2).toString()
              }
            }
          },
          items: [
            {
              name: 'Package payment',
              quantity: '1',
              category: 'DIGITAL_GOODS',
              unit_amount: {
                currency_code: 'USD',
                value: price.toFixed(2).toString(),
              },
            }
          ]
        }
      ]
    },
    advanced: {
      commit: 'true'
    },
    style: {
      label: 'paypal',
      layout: 'vertical'
    },
    onApprove: (data, actions) => {
      // console.log('onApprove - transaction was approved, but not authorized', data, actions);
      actions.order.get().then(details => {
        // console.log('onApprove - you can get full order details inside onApprove: ', details);
      });
    },
    onClientAuthorization: (data) => {
      // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
      if(data.status === 'COMPLETED'){
        try{
          if(this.userData.package){
            this.userData.package.endDate = this.convertDate(new Date());
            this.userData.package.normal += item.normal;
            this.userData.package.featured += item.featured;
            this.api.updateUser(this.userData.did, this.userData)
              .then( done =>{
                location.reload();
              })
          }
          else{
            this.userData.package = {
              endDate: this.convertDate(new Date()),
              normal: item.normal,
              featured: item.featured
            };
            this.api.updateUser(this.userData.did, this.userData)
              .then( done =>{
                location.reload();
              });
          }
        }
        catch(e){}
      }
      this.showSuccess = true;
    },
    onCancel: (data, actions) => {
      console.log('OnCancel', data, actions);
    },
    onError: err => {
      console.log('OnError', err);
    },
    onClick: (data, actions) => {
      console.log('onClick', data, actions);
    },
  };
  }

}