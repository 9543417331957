import { Router } from '@angular/router';
import { JobInterface } from './../../../interfaces/job.interface';
import { CandidateService } from './../../../services/candidate.service';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { map } from 'rxjs/operators';
import { ApplyJobInterface } from 'src/app/interfaces/apply-job.interface';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-can-search',
  templateUrl: './can-search.component.html',
  styleUrls: ['./can-search.component.css']
})
export class CanSearchComponent implements OnInit {
  language = localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
  setLanguage(lng) {
    console.log(lng);
    if (lng === 'en') {
      localStorage.setItem('language', 'en');
    }
    else {
      localStorage.setItem('language', 'ger');
    }
    this.translate.use(lng);
  }
  keyword = localStorage.getItem("searchKeyword");
  jobs: Array<JobInterface> = [];
  displayJobs: Array<JobInterface> = [];
  search = {} as JobInterface;
  shortlistJobs = [];
  applyJobObj = {} as ApplyJobInterface;
  education = [];
  work = [];
  userData = [];

  constructor(private canSer: CandidateService, private router: Router, private api: ApiService, public translate: TranslateService) {
    if (localStorage.getItem('language')) {
      translate.use(localStorage.getItem('language'));
    }
    else {
      translate.setDefaultLang('Portuguese');//2
      translate.addLangs(['English', 'Portuguese']);
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
    }
  }

  ngOnInit() {
    this.getJobs();
    this.filterJobs();

    this.api.getCandidateShortListedJobs(localStorage.getItem('uid'))
      .pipe(map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return { did, ...data };
      })))
      .subscribe((res: Array<any>) => {
        this.shortlistJobs = res;
        // console.log(res);
      });

    this.api.getCandidateEducationById(localStorage.getItem('uid'))
      .pipe(map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return { did, ...data };
      })))
      .subscribe((res: Array<any>) => {
        this.education = res;
      });

    this.api.getCandidateWorkExperienceById(localStorage.getItem('uid'))
      .pipe(map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return { did, ...data };
      })))
      .subscribe((res: Array<any>) => {
        this.work = res;
      });

    this.api.getUserProfile(localStorage.getItem('uid'))
      .pipe(map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return { did, ...data };
      })))
      .subscribe((res: Array<any>) => {
        this.userData = res;
      });
  }


  getJobs() {
    const key = this.keyword;
    console.log('Key is ' + key);
    const data: Array<JobInterface> = [];
    this.api.getAllJobs()
      .pipe(map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return { did, ...data };
      })))
      .subscribe((res: Array<any>) => {
        this.jobs = res;
        // console.log(res);
        this.jobs.forEach(function (elem, i) {
          var re = new RegExp(key, "gi");
          if (elem.job_title.match(re)) {
            data.push(elem);
          }
        });
        this.displayJobs = data;
      });
    // this.canSer.getAllJobs().subscribe(res => {
    //   this.jobs = res;
    //   console.log(res);
    //   this.jobs.forEach(function (elem, i) {
    //     var re = new RegExp(key, "gi");
    //       if (elem.job_title.match(re)) {
    //           data.push(elem);
    //       }
    //     });
    //     this.displayJobs = data;
    // }, err => {
    //   console.log(err);
    // });
  }

  filterJobs() {
    console.dir(this.displayJobs);
  }

  onSearch() {
    console.log('Keyword is ' + this.search.job_title);
    localStorage.setItem('searchKeyword', this.search.job_title);
    this.router.navigate(['/candidate/home/can-search']);
  }

  empJobSingle(index: number) {
    localStorage.setItem('jobSingleId', this.jobs[index].jobId);
    this.router.navigate(['/candidate/home/job-single']);
  }

  checkIfShortList(id) {
    let x = this.shortlistJobs.filter(data => data.jobId === id);
    if (x.length > 0) {
      return true;
    }
    else
      return false;
  }

  updateShortListJob(item) {
    console.log(item);
    if (this.checkIfShortList(item.did) === true) {
      let x = this.shortlistJobs.filter(data => data.jobId === item.did);
      this.api.deleteShortlistLife(x[0].did);
    }
    else {
      try {
        const date = new Date();
        this.applyJobObj.job_title = item.job_title;
        this.applyJobObj.job_type = item.job_type;
        this.applyJobObj.address = item.address;
        this.applyJobObj.applied_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        this.applyJobObj.jobId = item.did;
        this.applyJobObj.empId = item.userId;
        this.applyJobObj.candidateId = localStorage.getItem('uid');
      }
      catch (E) { }
      this.canSer.shortlistJob(this.applyJobObj).subscribe(res => {
        const data = JSON.parse(JSON.stringify(res));
        if (data.success) {
          swal.fire('Success', data.message, 'success');
        } else {
          swal.fire('Failure', data.message, 'error');
        }
      }, err => {
        console.log(err);
      });
    }
  }

  applyJob(item) {
    if (this.education.length === 0 || this.work.length === 0) {
      alert('Please complete your resume first.');
      this.router.navigate(['candidate/dashboard/resume']);
      return;
    }
    if (this.userData.length === 0) {
      alert('Please complete your profile first.');
      this.router.navigate(['candidate/dashboard/profile']);
      return;
    }

    try {
      const date = new Date();
      this.applyJobObj.job_title = item.job_title;
      this.applyJobObj.job_type = item.job_type;
      this.applyJobObj.address = item.address;
      this.applyJobObj.applied_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      this.applyJobObj.jobId = item.jobId;
      this.applyJobObj.empId = item.userId;
      this.applyJobObj.candidateId = localStorage.getItem('uid');
    }
    catch (e) { }
    this.canSer.applyJob(this.applyJobObj).subscribe(res => {
      const data = JSON.parse(JSON.stringify(res));
      if (data.success) {
        swal.fire('Success', data.message, 'success');
      } else {
        swal.fire('Failure', data.message, 'error');
      }
    }, err => {
      console.log(err);
    });
  }

}
