import { ApplyJobInterface } from './../../../interfaces/apply-job.interface';
import { CandidateService } from 'src/app/services/candidate.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ApiService } from 'src/app/services/api.service';
import { map } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-can-candidate-dashboard',
  templateUrl: './can-candidate-dashboard.component.html',
  styleUrls: ['./can-candidate-dashboard.component.css']
})
export class CanCandidateDashboardComponent implements OnInit {
  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
    setLanguage(lng){
      console.log(lng);
      if(lng === 'en'){
        localStorage.setItem('language','en');
      }
      else{
        localStorage.setItem('language','ger');
      }
      this.translate.use(lng);
    }
  username = JSON.parse(localStorage.getItem('userData')).name;
  appliedJobs: Array<ApplyJobInterface> = [];
  countApplied = 0;
  userProfile;
  jobs= [];
  totalJobs=0;

  constructor(private canSer: CandidateService, private api: ApiService, private router: Router, public translate: TranslateService) { 
    if(localStorage.getItem('language')){
          translate.use(localStorage.getItem('language'));
        }
        else{
          translate.setDefaultLang('Portuguese');//2
          translate.addLangs(['English', 'Portuguese']);
          const browserLang = translate.getBrowserLang();
          translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
        }}
  ngOnInit() {
    // this.getAppliedJobs();
    const user = JSON.parse(localStorage.getItem('user'));
    this.api.getAppliedJobs(user.uid)
      .pipe(map(actions => actions.map(a =>{
        const data= a.payload.doc.data();
        const did = a.payload.doc.id;
        return {did, ...data};
      })))
      .subscribe( (res:Array<any>) =>{
        this.appliedJobs = res;
        this.countApplied = this.appliedJobs.length;
      });
      console.log(user.uid);
    this.api.getUserProfile(user.uid)
    .pipe(map(actions => actions.map(a =>{
      const data= a.payload.doc.data();
      const did = a.payload.doc.id;
      return {did, ...data};
    })))
    .subscribe( (res:Array<any>) =>{
      if(res.length > 0){
        this.userProfile = res[0];
        console.log(this.userProfile);
        if(this.userProfile){
          this.api.getJobsByCategories(this.userProfile.categories)
          .pipe(map(actions => actions.map(a =>{
            const data= a.payload.doc.data();
            const did = a.payload.doc.id;
            return {did, ...data};
          })))
            .subscribe( (jobs: Array<any>) =>{
              this.jobs = jobs;
              this.totalJobs = this.jobs.length;
            });
        }
      }
    });
  }

  getAppliedJobs(){
    this.canSer.getAppliedJobsByUser().subscribe(res => {
      this.appliedJobs = res;
      this.countApplied = this.appliedJobs.length;
      console.log(this.countApplied);
      }, err => {
        console.log(err);
      });
  }

  appropriateJobs(){
    if(this.totalJobs > 0){
      localStorage.setItem('candidate-category',this.userProfile.categories);
      this.router.navigate(['candidate/dashboard/appropriate-for-me']);
    }

  }

}
