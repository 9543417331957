import { CandidateService } from './../../../services/candidate.service';
import { EmployerService } from 'src/app/services/employer.service';
import { Router } from '@angular/router';
import { JobInterface } from './../../../interfaces/job.interface';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { map } from 'rxjs/operators';
import { ApplyJobInterface } from 'src/app/interfaces/apply-job.interface';
import swal from 'sweetalert2';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-can-candidate-home',
  templateUrl: './can-candidate-home.component.html',
  styleUrls: ['./can-candidate-home.component.css']
})
export class CanCandidateHomeComponent implements OnInit {

  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
  setLanguage(lng){
    console.log(lng);
    if(lng === 'en'){
      localStorage.setItem('language','en');
    }
    else{
      localStorage.setItem('language','ger');
    }
    this.translate.use(lng);
  }
  jobs :Array<JobInterface> = [];
  jobsPosted;
  singleJob;
  search = {} as JobInterface;
  recent =[];
  allJobs=0;
  companies=0;
  candidates=0;
  shortlistJobs=[];
  applyJobObj = {} as ApplyJobInterface;

  constructor(private router: Router, private canSer: CandidateService, private api: ApiService, public translate: TranslateService) {
    if(localStorage.getItem('language')){
          translate.use(localStorage.getItem('language'));
        }
        else{
          translate.setDefaultLang('Portuguese');//2
          translate.addLangs(['English', 'Portuguese']);
          const browserLang = translate.getBrowserLang();
          translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
        } }

  ngOnInit() {
    this.api.getFeaturedJobs()
      .pipe(map(actions => actions.map(a =>{
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return {did, ...data};
      })))
      .subscribe( (res: Array<any>) =>{
        this.jobs = res;
      });
    this.api.getRecentJobs()
    .pipe(map(actions => actions.map(a =>{
      const data = a.payload.doc.data();
      const did = a.payload.doc.id;
      return {did, ...data};
    })))
    .subscribe( (res: Array<any>) =>{
      this.recent = res;
    });

    this.api.getAllJobs()
    .pipe(map(actions => actions.map(a =>{
      const data = a.payload.doc.data();
      const did = a.payload.doc.id;
      return {did, ...data};
    })))
    .subscribe( (res: Array<any>) =>{
      this.allJobs = res.length;
    });

    this.api.getAllCompanies()
    .pipe(map(actions => actions.map(a =>{
      const data = a.payload.doc.data();
      const did = a.payload.doc.id;
      return {did, ...data};
    })))
    .subscribe( (res: Array<any>) =>{
      this.companies = res.length;
    });

    this.api.getAllCanidates()
    .pipe(map(actions => actions.map(a =>{
      const data = a.payload.doc.data();
      const did = a.payload.doc.id;
      return {did, ...data};
    })))
    .subscribe( (res: Array<any>) =>{
      this.candidates = res.length;
    });

    this.api.getCandidateShortListedJobs(localStorage.getItem('uid'))
    .pipe(map(actions => actions.map(a =>{
      const data = a.payload.doc.data();
      const did = a.payload.doc.id;
      return {did, ...data};
    })))
    .subscribe( (res: Array<any>) =>{
      this.shortlistJobs = res;
    });
  }

  getJobs(){
    this.canSer.getAllJobs().subscribe(res => {
      this.recent = res;
      this.recent = this.recent.slice(0,5);
      console.dir(this.recent);
    }, err => {
      console.log(err);
    });
  }

  onSearch() {
    console.log('Keyword is ' + this.search.job_title);
    localStorage.setItem('searchKeyword', this.search.job_title);
    this.router.navigate(['/candidate/home/can-search']);
  }

  empJobSingle(index: number) {
    localStorage.setItem('jobSingleId', this.recent[index].jobId);
    this.router.navigate(['candidate/home/job-single']);
  }

  showJobs(type){
    this.router.navigate(['candidate/dashboard/appropriate-for-me',{
      category: type
    }]);
  }

  applyNow(item){
    localStorage.setItem('jobSingleId', item.did);
    this.router.navigate(['/candidate/home/job-single',{
      type: 'foryou'
    }]);
  }

  checkIfShortList(id){
    let x = this.shortlistJobs.filter(data => data.jobId === id);
    if(x.length > 0){
      return true;
    }
    else
      return false;
  }

  updateShortListJob(item){
    console.log(item);
    if(this.checkIfShortList(item.did) === true){
      let x = this.shortlistJobs.filter(data => data.jobId === item.did);
      this.api.deleteShortlistLife(x[0].did);
    }
    else{
      try{
        const date = new Date();
      this.applyJobObj.job_title = item.job_title;
      this.applyJobObj.job_type = item.job_type;
      this.applyJobObj.address = item.address;
      this.applyJobObj.applied_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      this.applyJobObj.jobId = item.did;
      this.applyJobObj.empId = item.userId;
      this.applyJobObj.candidateId = localStorage.getItem('uid');
      }
      catch(E){}
      this.canSer.shortlistJob(this.applyJobObj).subscribe( res => {
        const data  = JSON.parse(JSON.stringify(res));
        if (data.success) {
          swal.fire('Success', data.message, 'success');
        } else {
          swal.fire('Failure', data.message, 'error');
        }
      }, err => {
          console.log(err);
      });
    }
  }

}
