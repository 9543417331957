import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-single-jobs',
  templateUrl: './single-jobs.component.html',
  styleUrls: ['./single-jobs.component.css']
})
export class SingleJobsComponent implements OnInit {

  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
  setLanguage(lng){
    console.log(lng);
    if(lng === 'en'){
      localStorage.setItem('language','en');
    }
    else{
      localStorage.setItem('language','ger');
    }
    this.translate.use(lng);
  }
  category = localStorage.getItem('candidate-category');
  jobs=[];

  constructor(private api: ApiService, private router: Router, private route: ActivatedRoute, public translate: TranslateService) {
    if(localStorage.getItem('language')){
          translate.use(localStorage.getItem('language'));
        }
        else{
          translate.setDefaultLang('Portuguese');//2
          translate.addLangs(['English', 'Portuguese']);
          const browserLang = translate.getBrowserLang();
          translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
        } }

  ngOnInit() {
    this.route.params.subscribe( data =>{
      if(data.category){
        this.category = data.category;
      }
      else{
        this.category = localStorage.getItem('candidate-category');
      }
      this.api.getJobsByCategories(this.category)
      .pipe(map(actions => actions.map(a =>{
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return {did, ...data};
      })))
      .subscribe( (res: Array<any>) =>{
        this.jobs = res;
        console.log(res);
      });
    });
  }

  view(item){
    localStorage.setItem('jobSingleId', item.did);
    this.router.navigate(['/candidate/home/job-single',{
      type: 'foryou'
    }]);
  }

}
