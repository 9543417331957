import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-homee',
  templateUrl: './homee.component.html',
  styleUrls: ['./homee.component.css']
})
export class HomeeComponent implements OnInit {language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
setLanguage(lng){
  console.log(lng);
  if(lng === 'en'){
    localStorage.setItem('language','en');
  }
  else{
    localStorage.setItem('language','ger');
  }
  this.translate.use(lng);
}
  constructor( public translate: TranslateService) { 
    if(localStorage.getItem('language')){
      translate.use(localStorage.getItem('language'));
    }
    else{
      translate.setDefaultLang('Portuguese');//2
      translate.addLangs(['English', 'Portuguese']);
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
    }
  }

  ngOnInit() {
  }

}
