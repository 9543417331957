import { ApplyJobInterface } from './../../../interfaces/apply-job.interface';
import { EmployerService } from './../../../services/employer.service';
import { JobInterface } from './../../../interfaces/job.interface';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
  setLanguage(lng){
    console.log(lng);
    if(lng === 'en'){
      localStorage.setItem('language','en');
    }
    else{
      localStorage.setItem('language','ger');
    }
    this.translate.use(lng);
  }
  appliedJobs: Array<ApplyJobInterface> = [];
  resumesCount = 0;
  jobs :Array<JobInterface> = [];
  jobsPosted;
  featuredJobs=[];

  constructor(private router: Router, private empSer: EmployerService, private api: ApiService, public translate: TranslateService) {
    if(localStorage.getItem('language')){
      translate.use(localStorage.getItem('language'));
    }
    else{
      translate.setDefaultLang('Portuguese');//2
      translate.addLangs(['English', 'Portuguese']);
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
    }
   }

  ngOnInit() {
    this.getJobs();
    this.getResumes();

    this.api.getEmployerFeaturedJobs(localStorage.getItem('uid'))
      .pipe(map(actions=> actions.map(a =>{
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return {did, ...data};
      })))
      .subscribe((res: Array<any>) =>{
        this.featuredJobs = res;
      });
  }

  empJobSingle(index: number) {
    localStorage.setItem('jobSingleId', JSON.stringify(this.jobs[index].uid));
    this.router.navigate(['/employer/home/job-single']);
  }

  getJobs(){
    this.empSer.getJobsForCompany().subscribe(res => {
      this.jobs = res;
      this.jobsPosted = this.jobs.length;
      console.log(this.jobs);
    }, err => {
      console.log(err);
    });
  }

  getResumes(){
    const users = JSON.parse(localStorage.getItem('user'));
    this.empSer.getAppliedJobs(users.uid)
    .subscribe(res => {
      this.appliedJobs = res;
      this.resumesCount = this.appliedJobs.length;
    });
  }

  // getJobSingle
}
