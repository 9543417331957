import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplyJobInterface } from 'src/app/interfaces/apply-job.interface';
import { CandidateService } from 'src/app/services/candidate.service';
import { NgFlashMessageService } from 'ng-flash-messages';
import swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-can-applied-job',
  templateUrl: './can-applied-job.component.html',
  styleUrls: ['./can-applied-job.component.css']
})
export class CanAppliedJobComponent implements OnInit {
  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
    setLanguage(lng){
      console.log(lng);
      if(lng === 'en'){
        localStorage.setItem('language','en');
      }
      else{
        localStorage.setItem('language','ger');
      }
      this.translate.use(lng);
    }
  username = JSON.parse(localStorage.getItem('userData')).name;
  appliedJobs: Array<ApplyJobInterface> = [];
  constructor(private router: Router, private canSer: CandidateService, private ngFlashMessageService: NgFlashMessageService, public translate: TranslateService) { if(localStorage.getItem('language')){
    translate.use(localStorage.getItem('language'));
  }
  else{
    translate.setDefaultLang('Portuguese');//2
    translate.addLangs(['English', 'Portuguese']);
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
  }}

  ngOnInit() {
    this.getAppliedJobs();
  }

  getAppliedJobs() {
   this.canSer.getAppliedJobs().subscribe(res => {
    this.appliedJobs = res;
   }, err => {
     console.log(err);
   });
  }

  canJobSingle(index: number) {
    const uid = this.appliedJobs[index].jobId;
    localStorage.setItem('jobSingleId', uid);
    this.router.navigate(['/candidate/home/job-single']);
  }

  deleteJob(index: number) {
    this.canSer.deleteAppliedJob(this.appliedJobs[index].candidateId, this.appliedJobs[index].jobId).subscribe(res => {
      swal.fire('Deleted', 'Successfully deleted', 'success');
      this.ngFlashMessageService.showFlashMessage({
        messages: ["Job has been successfuly deleted"],
        dismissible: true,
        timeout: 5000,
        type: 'success'
      });
    }, err => {
      console.log(err);
    });
  }
}
