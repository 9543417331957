import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
  setLanguage(lng){
    console.log(lng);
    if(lng === 'en'){
      localStorage.setItem('language','en');
    }
    else{
      localStorage.setItem('language','ger');
    }
    this.translate.use(lng);
  }
    constructor(private router: Router, private location: Location, public translate: TranslateService) {
      if(localStorage.getItem('language')){
        translate.use(localStorage.getItem('language'));
      }
      else{
        translate.setDefaultLang('Portuguese');//2
        translate.addLangs(['English', 'Portuguese']);
        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
      }
  }
  ngOnInit() {
  }
  goHome() {
    this.location.back();
  }
}
