import { Component, ViewEncapsulation } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = 'E-Empregos';
  constructor(public translate: TranslateService) {
    translate.setDefaultLang('ger');//2
    translate.use(localStorage.getItem('language') ? localStorage.getItem('language') : 'ger');
  }
}
