import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { NgFlashMessageService } from 'ng-flash-messages';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
  setLanguage(lng){
    console.log(lng);
    if(lng === 'en'){
      localStorage.setItem('language','en');
    }
    else{
      localStorage.setItem('language','ger');
    }
    this.translate.use(lng);
  }

  email="";

  constructor(private ngFlashMessageService: NgFlashMessageService, private spinner: NgxSpinnerService, public translate: TranslateService,private authService: AuthenticationService, private router: Router) { }

  ngOnInit() {
  }

  sendEmail(){
    if(this.email !== ''){
      this.spinner.show();
      this.authService.forgotPassword(this.email)
      .then(res =>{
        this.spinner.hide();
        alert('check your email for password reset');
        this.router.navigate(['/login']);
      }, err =>{
        this.spinner.hide();
        alert(err.message);
      })
    }
  }

}
