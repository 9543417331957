import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployerService } from 'src/app/services/employer.service';
import { UserInterface } from 'src/app/interfaces/user.interface';
import { ApiService } from 'src/app/services/api.service';
import { map } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-emp-list',
  templateUrl: './emp-list.component.html',
  styleUrls: ['./emp-list.component.css']
})
export class EmpListComponent implements OnInit {
  language=localStorage.getItem('language') ? localStorage.getItem('language') : 'ger';
  setLanguage(lng){
    console.log(lng);
    if(lng === 'en'){
      localStorage.setItem('language','en');
    }
    else{
      localStorage.setItem('language','ger');
    }
    this.translate.use(lng);
  }
  constructor(private router: Router, private empSer: EmployerService, private api: ApiService, public translate: TranslateService) {
    if(localStorage.getItem('language')){
      translate.use(localStorage.getItem('language'));
    }
    else{
      translate.setDefaultLang('Portuguese');//2
      translate.addLangs(['English', 'Portuguese']);
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/English|Portuguese/) ? browserLang : 'English');
    }
   }
  emptoDisplay = [];
  emps= [];
  ngOnInit() {
    localStorage.setItem('empSingle', null);
    // this.getEmployers();
    this.api.getAllEmployerProfile()
      .pipe(map(actions => actions.map(a =>{
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return {did, ...data};
      })))
      .subscribe((res: Array<any>) =>{
        this.emps=res;
        this.emptoDisplay = this.emps
      })
  }
  empSingleRoute(id) {
    console.log(id);
    localStorage.setItem('empSingle', JSON.stringify(id));
    this.router.navigate(['/employer/dashboard/single-emp']);
  }
  // getEmployers() {
  //   this.empSer.getEmps().subscribe(res => {
  //     this.emps = res;
  //     this.emptoDisplay = this.emps;
  //   }, err => {
  //     console.log(err);
  //   });
  // }
  filterEmp(val: string) {
    this.emptoDisplay = this.emps;
    console.log(this.emptoDisplay);
    const data: Array<UserInterface> = [];
    if (val === 'all') {
      this.emptoDisplay = this.emps;
    } else {
      // this.emptoDisplay.forEach((item) => {
      //   if (item.name[0].toLowerCase() === val) {
      //     data.push(item);
      //   }
      // });
      this.emptoDisplay = this.emptoDisplay.filter(data => data.fullname.toLowerCase().startsWith(val.toLowerCase()));
      // this.emptoDisplay = data;
    }
  }
}
